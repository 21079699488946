@tailwind utilities;

@layer utilities {
    .table-container {
    @apply w-full;
    overflow-x: auto !important;
    overflow-y: auto !important;
  }

  .table-container>.table th,
  .table-container>.table td {
    @apply w-min;
    @apply whitespace-nowrap print:whitespace-normal;
  }

  .table-container>.table.no-whitespace th,
  .table-container>.table.no-whitespace td {
    @apply !whitespace-normal;
  }

  .table {
    @apply w-full min-w-min overflow-hidden border border-gray-200 rounded-xl;
    border-collapse: separate;
    border-spacing: 0 0;
  }

  .table thead th {
    @apply px-4 py-4 text-[#0a0a0c] text-sm text-left font-medium font-creato capitalize bg-gray-100 bg-opacity-90;
  }

  .table thead tr>th:first-child {
    @apply rounded-tl-xl;
  }

  .table thead tr>th:last-child {
    @apply rounded-tr-xl;
  }

  .table tbody tr:not(.table-no-data) td {
    @apply px-4 py-2.5 text-left font-creato border-b border-gray-200 text-qwid-cgray-100 text-plg;
  }

  .table tbody tr:not(.table-no-data):hover {
        @apply bg-qwid-gray-800 bg-opacity-60;
    }

  .table.table-align-top td {
    @apply align-top;
  }

  .table td .table-dropdown-align-top {
    @apply -mt-2;
  }

  .table-info {
    @apply px-2 py-20 text-center text-lg;
  }

  .table-info > *:first-child {
    @apply text-3xl mb-2 text-center;
  }

  .trade-table-trigger {
    @apply text-plg font-medium font-creato mb-1.5;
  }

}